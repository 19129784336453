import Request from '../Request.utils'
export interface ILoginRequestBody {
  email: string;
  password: string;
}
export interface IRegisterRequestBody {
  __key?: string;
  email: string;
  firstName: string;
  lastName: string;
  otherData?: any;
  password: string;
  photoUrl?: string;
  role?: string;
}


class UserAPI {
  login = async (body: ILoginRequestBody) => {
    const res = await Request.call('/api/users/login', 'POST', undefined, undefined, body, );
    if (res.data.data && res.data.data.token) Request.setToken(res.data.data.token)
    return res;
  }
  logout = async () => {
    const res = await Request.call('/users/logout', 'POST', undefined, undefined, undefined, );
    Request.setToken('')
    return res;
  }
  register = async (body: IRegisterRequestBody) => {
    const res = await Request.call('/api/users/register', 'POST', undefined, undefined, body, );
    return res;
  }
  me = async () => {
    const res = await Request.call('/api/users/me', 'GET', undefined, undefined, undefined, );
    return res;
  }
}
export default new UserAPI()