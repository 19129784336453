export const SCREEN = {
  Login: 'Login',
  Home: 'Home',
  SampleEditor: 'SampleEditor',
  SampleEditorTheChild: 'SampleEditorTheChild',
  Admin: 'Admin',
  AdminQuyUoc: 'AdminQuyUoc',
  AdminOrder: 'AdminOrder',
  AdminMoney: 'AdminMoney',
  AdminInfo: 'AdminInfo',
  CTVOrderReadOnly: 'CTVOrderReadOnly',
};
