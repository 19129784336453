import React, { useState, useEffect } from 'react';
import { Col, Row, Text, TouchField, Input, Grid, Button, modal, useUIState } from 'components';
import { useDynamicResponsiveValue } from 'quickly-react';
import { ScrollView } from 'react-native';
import { VarHelper } from 'helpers';
import { COLOR, SITE_INFO, CTV } from 'const';
import { MaterialIcons, Ionicons } from '@expo/vector-icons';
import Store from 'store';
import Swal from 'sweetalert2'

const singletonData = {
  name: '',
  sdt: '',
  address: '',
  note: '',
  shippingFee: 0,
}

export const SubmitOrder = ({ carts: c, setCarts: sC, onPlaceOrderDone, ctv }) => {
  const [name, setName] = useState(singletonData.name);
  const [sdt, setSdt] = useState(singletonData.sdt);
  const [address, setAddress] = useState(singletonData.address);
  const [note, setNote] = useState(singletonData.note);
  const [shippingFee, setShippingFee] = useState(singletonData.shippingFee);
  const [paymentType, setPaymentType] = useState<'COD' | 'BANK'>('COD');
  const [carts, setCarts] = useState(c);
  const [didCopy, setDidCopy] = useState(false);
  const updateCarts = (v) => {
    sC(v);
    setCarts(v);
  };

  const [{ loading }, setUI] = useUIState();

  useEffect(() => {
    if (paymentType === 'BANK') {
      setShippingFee(SITE_INFO.SHIPPING_FEE_BANK)
    } else {
      setShippingFee(SITE_INFO.SHIPPING_FEE_COD)
    }
  }, [paymentType]);

  useEffect(() => {
    singletonData.name = name;
    singletonData.sdt = sdt;
    singletonData.address = address;
    singletonData.note = note;
    singletonData.shippingFee = shippingFee;
  }, [name, sdt, address, note, shippingFee]);
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const totalOrderVND = carts.length === 0 ? 0 : shippingFee + carts.reduce((a, b) => ({ price: a.price + b.price })).price;

  const copyBankNumber = () => {
    const copyToClipboard = str => {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    };
    copyToClipboard(getBankInfo().BANK_NUMBER);
    setDidCopy(true);
    setTimeout(() => {
      setDidCopy(false);
    }, 1000);
  }

  const showError = (message) => {
    Swal.fire({
      title: 'Có lỗi xảy ra!',
      text: message,
      icon: 'error',
      confirmButtonText: 'OK'
    })
  }

  const showAlert = message => {
    Swal.fire(
      'Thành công!',
      message,
      'success'
    )
  }

  const placeOrder = async () => {
    if (!name) return showError('Vui lòng điền tên');
    if (!sdt) return showError('Vui lòng điền sdt');
    if (!address) return showError('Vui lòng điền địa chỉ nhận hàng');
    setUI({ loading: true });
    const res = await Store.Api.Order.upsert({
      name,
      sdt,
      address,
      note,
      shippingFee: shippingFee,
      paymentMethod: paymentType,
      shippingStatus: 'pending-ship',
      paymentStatus: 'not-receive',
      carts,
      ctv: ctv || '',
    });
    setUI({ loading: false });
    if (res.data.success) {
      showAlert('Cảm ơn bạn đã đặt hàng, chúng tôi sẽ xử lý đơn hàng của bạn sớm.');
      updateCarts([]);
      onPlaceOrderDone();
      modal.hide();
    }
  }

  const getBankInfo = () => {
    console.log('ctv', ctv);
    console.log(CTV[ctv]);
    if (!ctv || !CTV[ctv]) return SITE_INFO;
    return CTV[ctv];
  }

  return (
    <Col round1 bgWhite shadow width={rV({ xs: 360, md: 600 })}>
      <ScrollView
        style={{ height: 500 }}
        contentContainerStyle={{ padding: 20 }}
      >
        <Text mb1>Kiểm tra lại đơn hàng</Text>
        <Row p1 borderBottomColor={SITE_INFO.BRAND_COLOR} borderBottomWidth={1}>
          <Col flex1>
            <Text bold>Seri</Text>
          </Col>
          <Col flex1>
            <Text bold>Mệnh giá</Text>
          </Col>
          <Col flex1>
            <Text bold>Giá bán</Text>
          </Col>
        </Row>
        {carts.map(val => {
          return (
            <Row key={val.id} mb1 p1>
              <Col flex1>
                <Text>{val.code} {val.seri}</Text>
              </Col>
              <Col flex1>
                <Text>{VarHelper.formatMoney(val.displayValue)}</Text>
              </Col>
              <Row flex1 justifyContent={'space-between'}>
                <Text>{VarHelper.formatMoney(val.price)}</Text>

                <TouchField
                  width={30} height={30}
                  borderRadius={15}
                  onPress={() => {
                    updateCarts(carts.filter(item => item.id !== val.id));
                  }}
                >
                  <MaterialIcons name="delete-forever" size={24} color={COLOR.GREY} />
                </TouchField>
              </Row>
            </Row>
          )
        })}
        <Row mb1 p1 borderTopColor={SITE_INFO.BRAND_COLOR} borderTopWidth={1}>
          <Col flex1>
            <Text>Phí ship</Text>
          </Col>
          <Col flex1>
            <Text>{VarHelper.formatMoney(shippingFee)}</Text>
          </Col>
          <Col flex1>
            <Text mb0>Tổng:</Text>
            <Text color='red' bold>{VarHelper.formatMoney(totalOrderVND)}</Text>
          </Col>
        </Row>

        <Text mt2>Điền thông tin người nhận</Text>
        <Grid xs='100%' md='50%' marginHorizontal={-5}>
          <Col m0 p0>
            <Text caption mb1>Họ và tên</Text>
            <Input
              value={name}
              onChange={setName}
            />
          </Col>
          <Col m0 p0>
            <Text caption mb1>SDT</Text>
            <Input
              value={sdt}
              onChange={setSdt}
            />
          </Col>
          <Col m0 p0>
            <Text caption mb1>Địa chỉ nhận hàng</Text>
            <Input
              value={address}
              onChange={setAddress}
            />
          </Col>
          <Col m0 p0>
            <Text caption mb1>Ghi chú</Text>
            <Input
              value={note}
              onChange={setNote}
            />
          </Col>
        </Grid>
        <Text mt2>Chọn hình thức thanh toán</Text>
        {SITE_INFO.SHIPPING_FEE_BANK < SITE_INFO.SHIPPING_FEE_COD && (
          <Text caption>* Chọn hình thức chuyển khoản sẽ được <Text bold color={SITE_INFO.BRAND_COLOR}>giảm phí ship</Text></Text>
        )}
        <Row mt1>
          <Button
            outline={paymentType !== 'COD'}
            text='Trả khi nhận hàng - COD'
            height={30}
            borderRadius={15}
            onPress={() => {
              setPaymentType('COD')
              setShippingFee(30000)
            }}
            flex1
          />
          <Button
            ml2
            outline={paymentType !== 'BANK'}
            text='Chuyển khoản'
            height={30}
            borderRadius={15}
            onPress={() => {
              setPaymentType('BANK')
            }}
            flex1
          />
        </Row>
        {paymentType === 'BANK' && (
          <Col mt2>
            <Text mb2>Vui lòng chuyển khoản <Text bold>{VarHelper.formatMoney(totalOrderVND)} đ</Text> theo thông tin sau:</Text>
            <Grid xs='100%' md='50%' marginHorizontal={-5}>
              <Col m0 p0>
                <Text mb1 caption>Tên tài khoản</Text>
                <Col height={40} ph2 justifyContent='center' borderThin borderRadius={20}>
                  <Text bold color={SITE_INFO.BRAND_COLOR}>{getBankInfo().BANK_HOLDER_NAME}</Text>
                </Col>
              </Col>
              <Col m0 p0>
                <Text mb1 caption>Số tài khoản {didCopy ? '(đã copy)' : ''}</Text>
                <Col height={40} ph2 justifyContent='center' borderThin borderRadius={20}>
                  <Text bold color={SITE_INFO.BRAND_COLOR}>{getBankInfo().BANK_NUMBER}</Text>
                  <Col absolute top={0} right={0}>
                    <TouchField width={40} height={40} borderRadius={20} middle
                      onPress={copyBankNumber}
                    >
                      <Ionicons name="copy-outline" size={16} color="black" />
                    </TouchField>
                  </Col>
                </Col>
              </Col>
              <Col m0 p0>
                <Text mb1 caption>Ngân hàng</Text>
                <Col height={40} ph2 justifyContent='center' borderThin borderRadius={20}>
                  <Text bold color={SITE_INFO.BRAND_COLOR}>{getBankInfo().BANK_NAME}</Text>
                </Col>
              </Col>
              <Col m0 p0>
                <Text mb2 caption></Text>
                <Button
                  isLoading={loading}
                  text='Đặt hàng'
                  onPress={placeOrder}
                  width='100%'
                  height={40}
                  borderRadius={20}
                  backgroundColor='red'
                  bgHovered={SITE_INFO.BRAND_COLOR}
                />
              </Col>
            </Grid>

          </Col>
        )}

        {paymentType === 'COD' && (
          <Col mt2>
            <Text mb2>Vui lòng chuẩn bị <Text bold>{VarHelper.formatMoney(totalOrderVND)} đ</Text> để trả khi nhận hàng:</Text>
            <Button
              mt1
              text='Đặt hàng'
              onPress={placeOrder}
              width='100%'
              height={40}
              borderRadius={20}
              backgroundColor='red'
              bgHovered={SITE_INFO.BRAND_COLOR}
            />
          </Col>
        )}

      </ScrollView>
    </Col>
  )
};