import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, CMSLayout, Grid, Input, Button } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

const Admin: IScreen = () => {
  const { navigate } = useNavFunc();

  const dataId = useRef('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [sdt, setSdt] = useState('');
  const [email, setEmail] = useState('');
  const [fb, setFb] = useState('');
  const [fbAppID, setFbAppID] = useState('');
  const [fbPageID, setFbPageID] = useState('');
  const [address, setAddress] = useState('');
  const [bankNumber, setBankNumber] = useState('');
  const [bankHolderName, setBankHolderName] = useState('');
  const [bankName, setBankName] = useState('');
  const [shippingFeeCOD, setShippingFeeCOD] = useState();
  const [shippingFeeBank, setShippingFeeBank] = useState();
  const [brandColor, setBranColor] = useState('');
  const [coverUrl, setCoverUrl] = useState('');
  const [moneyDisplayOrder, setMoneyDisplayOrder] = useState('');

  useEffect(() => {
    (async () => {
      const res = await Store.Api.Data.list({
        type: 'info',
      }, {
        field1: 'MAIN_INFO',
      });
      console.log('res', res.data);
      if (res.data.data.length > 0) {
        dataId.current = res.data.data[0].id;
        const { data } = res.data.data[0];
        setName(data.name);
        setDescription(data.description);
        setSdt(data.sdt);
        setFb(data.fb);
        setBankNumber(data.bankNumber);
        setBankHolderName(data.bankHolderName);
        setBankName(data.bankName);
        setShippingFeeCOD(data.shippingFeeCOD);
        setShippingFeeBank(data.shippingFeeBank);
        setBranColor(data.brandColor);
        setAddress(data.address);
        setCoverUrl(data.coverUrl);
        setMoneyDisplayOrder(data.moneyDisplayOrder);
        setFbAppID(data.fbAppID);
        setFbPageID(data.fbPageID);
      }
    })();
  }, []);

  const save = async () => {
    if (!name) return alert('Vui lòng nhập tên');
    const obj = {
      id: dataId.current,
      field1: 'MAIN_INFO',
      publicPermission: {
        c: false,
        d: false,
        r: true,
        u: false,
      },
      data: {
        name,
        description,
        sdt,
        fb,
        bankNumber,
        bankHolderName,
        bankName,
        shippingFeeCOD,
        shippingFeeBank,
        brandColor,
        address,
        coverUrl,
        moneyDisplayOrder,
        fbAppID,
        fbPageID,
      }
    }
    if (!obj.id) delete obj.id;
    const res = await Store.Api.Data.upsert({ type: 'info' }, obj);
    if (res.data.success) {
      alert('Đã lưu thành công');
    }
  };

  return (
    <CMSLayout requireAuthen>
      <Col flex1>
        <Grid xs='100%' md='50%' mb2>
          <Col p1 m1>
            <Text mb1>Tên website</Text>
            <Input
              value={name}
              onChange={setName}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Mô tả</Text>
            <Input
              value={description}
              onChange={setDescription}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>SDT</Text>
            <Input
              value={sdt}
              onChange={setSdt}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Email</Text>
            <Input
              value={email}
              onChange={setEmail}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Địa chỉ</Text>
            <Input
              value={address}
              onChange={setAddress}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Facebook</Text>
            <Input
              value={fb}
              onChange={setFb}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Facebook Page ID</Text>
            <Input
              value={fbPageID}
              onChange={setFbPageID}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Facebook App ID</Text>
            <Input
              value={fbAppID}
              onChange={setFbAppID}
              backgroundColor='white'
            />
          </Col>

        </Grid>

        <Grid xs='100%' md='50%' mb2>
          <Col p1 m1>
            <Text mb1>Số tài khoản ngân hàng</Text>
            <Input
              value={bankNumber}
              onChange={setBankNumber}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Tên chủ tài khoản</Text>
            <Input
              value={bankHolderName}
              onChange={setBankHolderName}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Tên ngân hàng</Text>
            <Input
              value={bankName}
              onChange={setBankName}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Thiết lập: Phí ship COD</Text>
            <Input
              value={shippingFeeCOD}
              onChange={setShippingFeeCOD}
              backgroundColor='white'
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Thiết lập: Phí ship BANK</Text>
            <Input
              value={shippingFeeBank}
              onChange={setShippingFeeBank}
              backgroundColor='white'
            />
          </Col>

        </Grid>

        <Grid xs='100%' mb2>
          <Col p1 m1>
            <Text mb1>Thiết lập thứ tự hiển thị các tờ tiền</Text>
            <Text mb1 caption>* Lưu ý: nhập Mã viết tắt trong phần Quy Ước, phân cách bởi dấu phẩy</Text>
            <Input
              value={moneyDisplayOrder}
              onChange={setMoneyDisplayOrder}
              backgroundColor='white'
            />
          </Col>
        </Grid>

        <Grid xs='100%' md='50%' mb2>
          <Col p1 m1>
            <Text mb1>Màu sắc chủ đạo</Text>
            <Input
              value={brandColor}
              onChange={setBranColor}
              backgroundColor={brandColor || 'white'}
            />
          </Col>
          <Col p1 m1>
            <Text mb1>Ảnh bìa URL</Text>
            <Input
              value={coverUrl}
              onChange={setCoverUrl}
            />
          </Col>
        </Grid>

        <Button
          m2
          marginTop={0}
          text='Lưu'
          borderRadius={20}
          width={70}
          height={40}
          onPress={save}
        />
      </Col>
    </CMSLayout>
  )
};

Admin.routeInfo = {
  title: 'Thông tin website',
  path: '/admin/info',
};

export default Admin;