import BottleGoose from './svg/BottleGoose';


export const SVG = {
  BottleGoose,
}

export const ASSETS = {
  MONEY_BG: require('./img/money-bg.jpg'),
  ZALO: require('./img/zalo.png'),
}